import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/economic-justice-workers-rights/economic-justice-workers-rights-hero.webp'
import accomplishment1 from '../../images/issues/economic-justice-workers-rights/economic-justice-workers-rights-accomplishment-1.webp'
import accomplishment2 from '../../images/issues/economic-justice-workers-rights/economic-justice-workers-rights-accomplishment-2.webp'

const IssuesEconomicJusticeWorkersRightsPage = () => (
  <Layout>
    <Seo title="Issues - Economic Justice &amp; Worker's Rights" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full max-h-96 object-cover object-pos-0-40" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero}
              alt=""
            />
        </div>
        <div className="max-w-5xl mx-auto">
          <h1 className="pt-10 font-bold text-6xl">Economic Justice &amp; Worker's Rights</h1>
          <p className="pt-5 text-2xl leading-relaxed">
            The success of Providence and its people requires a strong commitment to economic justice that cannot falter during these difficult times affected by the COVID-19 pandemic. The current and future economy depends on protecting labor rights, workplace safety, and sufficient wages to support a healthy and happy life. The creation of sustainable jobs is just one part of the plan that fuels Providence's economic recovery and growth. John offers a multi-faceted solution that includes affordable childcare, a stronger stance on workers' rights, access to legal counsel, and the promotion of policies that work to address wealth inequality.
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          {/*TODO: L side - bullet point, R side - image - TEXT John when I'm working on this, Sanctuary, John visiting Boston - Mass & Cass, etc.*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Supported Brown University Graduate School Union On Contract Negotiations</h3>
              <p>• Lead sponsor on legislation calling on Brown University to meet GLO's demands and “lead by example and set the standard for fair compensation.” Standing in support and solidarity with Graduate Student Union leaders and organizers, led the University to ultimately meet its demands of a 2.5 percent raise and $750 payment for pandemic costs. Read more <a href="https://www.browndailyherald.com/article/2021/05/glo-reaches-tentative-agreement-with-university-on-pay-raise" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Sponsor On Ordinance Ensuring the Jobs of Over 700 Providence Hotel Employees</h3>
              <p>• Sponsor on an ordinance ensuring that any hotel worker who was laid off or furloughed due to the COVID-19 pandemic would be rehired before other candidates, as City hotels come back online. The Hospitality Worker Comeback legislation includes protections and enforcement for employees that are not brought back to work, including the right to bring legal action and penalties.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>

          <p className="text-xl mt-5">The success of Providence and its people requires a strong commitment to economic justice that cannot falter during these difficult times affected by the COVID-19 pandemic. The current and future economy depends on protecting labor rights, workplace safety, and sufficient wages to support a healthy and happy life. The creation of sustainable, green jobs is just one part of the plan that fuels Providence's economic recovery and growth. John offers a multi-faceted solution that includes affordable childcare, a stronger stance on workers' rights, access to legal counsel, and promotion of policies that work to eradicate wealth inequality.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesEconomicJusticeWorkersRightsPage
